/**
 * @name: 介绍人管理-介绍人信息管理接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 介绍人管理-介绍人信息管理接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import { IReferring, IReferringParams } from './types';


export const referringListApi = (nameOrPhone?: string) => api.get<IReferring[]>('/admin/agent/getIntroduce', {nameOrPhone})

export const referringQueryApi = (params: IReferringParams) => api.get<IPageRes<IReferring[]>>('/admin/agent/queryIntroducePage', params)

export const referringPageApi = (params: IReferringParams) => api.get<IPageRes<IReferring[]>>('/admin/introduce/queryByPage', params)

export const referringModifyApi = (data: Partial<IReferring>) => api.postJ('/admin/introduce/modify', data)

export const referringCreateApi = (data: Partial<IReferring>) => api.postJ('/admin/introduce/create', data)

export const referringDetailApi = (id: string) => api.get<IReferring>('/admin/introduce/detail/' + id)

export const referringAgentApi = (params: any) => api.get('/admin/introduce/queryAgentIntroduceDetailByPage', params)
