
/**
 * @name: 介绍人管理-介绍人信息管理
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 介绍人管理-介绍人信息管理
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import {
  referringPageApi,
  referringModifyApi,
  referringCreateApi,
  referringDetailApi,
  referringAgentApi
} from '@/apis/referring/list'
import {
  IReferring,
  IReferringParams
} from '@/apis/referring/list/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from '@/utils/common'
import { checkPhone } from '@/constants/validators'

@Component({})
export default class referringList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IReferring[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IReferringParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IReferring> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    addTitle: '新增介绍人',
    editTitle: '编辑介绍人',
    labelWidth: '120px',
    column: [
      {
        label: '介绍人姓名',
        prop: 'introduceName',
        align: 'center',
        maxlength: 20,
        rules: [
          {
            required: true,
            message: '介绍人姓名不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, cb: Function) => {
              if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
                cb(new Error('介绍人姓名只能输入中文、字母、数字'))
                return
              }
              cb()
            },
            trigger: 'blur'
          }
        ],
        span: 12
      },
      {
        label: '介绍人',
        prop: 'introduceNameKeywords',
        placeholder: '介绍人姓名/手机号',
        search: true,
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: '手机号',
        prop: 'phone',
        align: 'center',
        maxlength: 11,
        editSlot: true,
        span: 12,
        rules: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          },
          {
            validator: checkPhone,
            trigger: 'blur'
          }
        ],
      },
      {
        label: '介绍代理商数量',
        prop: 'agentQuantity',
        align: "center",
        slot: true,
        addHide: true,
        editSlot: true,
        span: 12
      },
      {
        label: '状态',
        prop: 'status',
        type: 'select',
        "align": "center",
        slot: true,
        addHide: true,
        editHide: true,
        search: true,
        dicData: [
          {
            label: '启用',
            value: 1
          },
          {
            label: '禁用',
            value: 2
          }
        ]
      },
      {
        label: '备注',
        prop: 'remarks',
        type: 'textarea',
        "align": "center",
        "overHidden": true,
        maxlength: 100,
        showWordLimit: true
      },
      {
        "label": "创建时间",
        "prop": "addTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true,
        addHide: true,
        editHide: true
      },
    ]
  }


  agentDialog = false

  agentTableLoading = false

  agentModelForm: any = {}

  agentTableData: any = []

  agentTableTotal = 0

  agentQueryParam: any = {
    introduceId: '',
    page: '',
    limit: 10
  }

  agentCrudOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '代理商编号',
        prop: 'agentId',
        align: 'center'
      },
      {
        label: '代理商名称',
        prop: 'agentName',
        align: 'center'
      },
      {
        label: '负责人',
        prop: 'agentPersonInCharge',
        align: 'center'
      },
      {
        label: '手机号',
        prop: 'agentPhone',
        align: 'center'
      },
      {
        label: '介绍人分成比例',
        prop: 'commission',
        align: 'center',
        slot: true
      }
    ]
  }

  getList () {
    this.tableLoading = true

    const query: IReferringParams = deepCopy(this.queryParam)

    if (query.addTime && query.addTime.length == 2) {
      query.addTimeStart = query.addTime[0]
      query.addTimeEnd = query.addTime[1]
    } else {
      query.addTimeStart = ''
      query.addTimeEnd = ''
    }
    delete query.addTime

    referringPageApi(query).then(e => {
      if (e && e.data) {
        this.tableLoading = false
        this.tableData = e.data.list
        this.tableTotal = e.data.total
      }
    })
  }

  openDetail (row: IReferring, index: number) {
    referringDetailApi(row.id).then(e => {
      if (e && e.data) {
        // @ts-ignore
        this.$refs.crudRef.rowView(e.data, index)
      }
    })
  }

  openEdit (row: IReferring, index: number) {
    referringDetailApi(row.id).then(e => {
      if (e && e.data) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e.data, index)
      }
    })
  }

  switchStatus (id: string, status: number) {
    referringModifyApi({id, status}).then(e => {
      if (e && e.data) {
        this.getList()
        this.$message.success('操作成功!')
      }
    })
  }

  openAgentDialog(row: IReferring) {
    this.agentQueryParam.introduceId = row.id
    this.agentQueryParam.page = 1
    this.agentQueryParam.limit = 10
    this.getAgentList()
    this.agentDialog = true
  }

  getAgentList () {
    this.agentTableLoading = true
    referringAgentApi(this.agentQueryParam).then(e => {
      if (e && e.data) {
        this.agentTableLoading = false
        this.agentTableData = e.data.list
        this.agentTableTotal = e.data.total
      }
    })
  }

  rowSave (form: IReferring, done: Function, loading: Function) {
    const data = deepCopy(form)
    delete data.addTime
    referringCreateApi(data).then(e => {
      if (e && e.data) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IReferring, done: Function, loading: Function) {
    const data = deepCopy(form)
    delete data.addTime
    referringModifyApi(data).then(e => {
      if (e && e.data) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  created () {
    this.getList()
  }
}
